.container{
  position: relative;
  border-radius: 16px;
  padding: 8px;
  z-index: 1;
  transition: transform 250ms ease 0s;
  box-sizing: border-box;
  margin: 30px auto;
  border: 1px solid var(--border-color);
}

.title{
  font-size: 16px;
}
@primary-color: #1DA57A;