@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

.loading-icon{
  animation: 2s linear 0s infinite normal none running rotate;
  margin: 20px auto;
  width: 94px;
  height: 94px;
}
.task-item{
  box-shadow: 0 0 5px #ccc;
}
@primary-color: #1DA57A;